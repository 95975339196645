import Contact from '../components/contact/Contact'

const ContactPage = () => {
    return (
        <div className="Contact">
            <Contact />
        </div>
    );
};

export default ContactPage;
